import "./header.css"
import React, { useState } from 'react';
import { Route, Routes, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Download from "../../page/dowload/dowload"
import News from "../../page/news/news"
import AboutUs from "../../page/aboutus/aboutus"

export default function Header() {

    const [selectedOption] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const { t, i18n } = useTranslation(); 

    const chanageLanguage = (language) => {
        i18n.changeLanguage(language)
    }

    return (
        <>
        <header>
            <div className="firstHeader">
                <a className="firstHeader__item Shop" href="https://www.kickstarter.com/projects/universerift/universe-rift-mmorpg-with-full-loot-sandbox-system?ref=discovery_category_newest&total_hits=21593&category_id=35">{t('header.supp')}</a>
                
                
                <div className="firstHeader__item firstHeader__custom-selector">
                    <button className="selector-button" onClick={() => setIsOpen(!isOpen)}>
                        {selectedOption || 
                            <svg className="firstHeader__imglanguage" version="1.0"  xmlns="http://www.w3.org/2000/svg"
                            width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
                            preserveAspectRatio="xMidYMid meet">
                           
                           <g  transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
                           <path d="M2300 5105 c-425 -46 -802 -183 -1155 -417 -551 -366 -936 -926
                           -1079 -1570 -81 -361 -81 -741 -1 -1103 240 -1086 1152 -1893 2255 -1995 63
                           -6 131 -12 150 -14 72 -7 387 15 502 34 813 136 1495 638 1868 1373 188 371
                           273 725 273 1137 0 224 -16 373 -59 570 -147 662 -552 1234 -1136 1606 -469
                           299 -1068 439 -1618 379z m180 -232 c-1 -101 -39 -765 -43 -770 -14 -13 -385
                           62 -405 82 -18 18 310 664 360 708 11 9 35 17 54 17 33 0 34 -1 34 -37z m336
                           -49 c120 -204 341 -662 326 -675 -22 -20 -301 -59 -420 -59 l-73 0 6 83 c7 98
                           35 659 35 700 0 27 2 28 42 25 41 -3 44 -5 84 -74z m-795 -155 c-54 -107 -118
                           -237 -141 -289 -24 -52 -46 -99 -50 -104 -8 -8 -142 58 -190 94 l-24 18 49 59
                           c92 110 401 411 426 415 13 2 25 3 27 2 2 -1 -42 -89 -97 -195z m1070 181 c25
                           0 258 -226 401 -390 102 -116 108 -125 91 -138 -21 -17 -224 -114 -228 -110
                           -1 2 -24 53 -50 113 -26 61 -96 206 -157 324 -68 133 -103 211 -93 207 9 -3
                           25 -6 36 -6z m-1497 -162 c-4 -7 -37 -46 -73 -86 l-66 -72 -29 29 c-16 16 -25
                           34 -21 40 6 11 172 99 188 100 5 1 5 -5 1 -11z m2113 -80 c50 -29 101 -60 114
                           -69 l24 -17 -20 -22 c-42 -47 -46 -45 -139 61 -48 54 -83 99 -78 99 4 0 49
                           -24 99 -52z m-2435 -186 l47 -56 -35 -46 c-58 -77 -228 -332 -269 -402 -22
                           -38 -42 -68 -45 -68 -9 0 -102 99 -153 163 -36 44 -45 63 -38 75 30 56 404
                           391 436 391 6 0 31 -26 57 -57z m2910 -170 c119 -116 258 -272 258 -292 0 -4
                           -44 -49 -97 -100 -110 -106 -95 -108 -166 20 -22 41 -92 151 -155 244 l-113
                           169 52 53 c43 44 55 51 71 43 10 -5 77 -67 150 -137z m-2510 -141 l77 -42 -46
                           -127 c-25 -70 -66 -194 -90 -277 -25 -82 -48 -158 -51 -167 -6 -16 -21 -11
                           -130 42 -68 33 -161 84 -208 115 l-84 55 52 87 c62 106 127 205 221 335 l70
                           97 56 -38 c31 -21 91 -57 133 -80z m2136 -48 c71 -100 191 -293 232 -372 22
                           -43 22 -45 4 -58 -42 -32 -152 -89 -267 -138 -181 -78 -159 -83 -192 42 -15
                           60 -56 191 -90 293 l-62 185 141 71 c78 39 146 72 153 73 7 0 43 -43 81 -96z
                           m-1781 -97 c90 -27 256 -61 347 -72 l58 -7 -6 -241 c-4 -132 -9 -269 -12 -303
                           l-6 -62 -47 5 c-133 16 -275 38 -351 55 -83 18 -242 60 -248 65 -6 4 55 215
                           98 339 23 66 51 148 63 183 12 34 22 62 22 62 1 0 38 -11 82 -24z m1260 -181
                           c68 -201 125 -407 116 -416 -10 -9 -178 -44 -303 -64 -96 -15 -467 -42 -474
                           -34 -5 5 14 579 19 597 3 8 28 12 80 12 103 0 238 16 370 44 61 13 116 23 124
                           22 9 -1 34 -60 68 -161z m-2508 -37 l93 -96 -40 -89 c-54 -121 -105 -260 -137
                           -373 -14 -52 -28 -101 -31 -108 -3 -10 -16 -5 -47 18 -77 56 -248 212 -258
                           234 -18 38 102 298 224 489 l50 79 26 -29 c15 -15 69 -71 120 -125z m3873
                           -123 c22 -44 61 -129 85 -189 l44 -110 -43 -44 c-50 -50 -227 -197 -232 -191
                           -2 2 -15 45 -29 96 -23 80 -60 185 -122 345 l-19 46 34 29 c19 15 71 60 115
                           100 l80 71 23 -36 c13 -20 41 -73 64 -117z m-3535 -148 c95 -61 203 -119 303
                           -162 47 -21 86 -39 88 -40 2 -2 -6 -49 -17 -106 -19 -101 -43 -273 -56 -411
                           -4 -38 -9 -68 -12 -68 -8 0 -165 57 -233 85 -87 36 -340 166 -340 175 0 18 72
                           265 98 335 36 97 94 235 98 235 2 0 34 -20 71 -43z m3066 -89 c39 -92 74 -198
                           107 -320 l28 -108 -96 -54 c-168 -94 -492 -228 -492 -203 -1 44 -32 293 -50
                           396 -11 68 -20 124 -18 125 2 2 57 24 123 50 66 25 174 76 240 111 66 35 123
                           64 126 64 3 1 18 -27 32 -61z m-2375 -212 c128 -36 321 -74 468 -91 71 -8 129
                           -15 130 -15 3 0 -8 -536 -11 -545 -7 -24 -387 20 -629 72 l-129 28 7 100 c9
                           147 59 475 72 475 3 0 44 -11 92 -24z m1676 -182 c15 -91 30 -210 33 -264 5
                           -90 4 -98 -13 -103 -170 -51 -489 -97 -776 -113 l-118 -6 0 209 c0 114 3 238
                           6 274 l7 66 198 12 c186 11 424 44 549 75 93 24 84 35 114 -150z m-3099 31
                           c38 -33 107 -87 153 -120 75 -53 83 -62 77 -85 -14 -60 -16 -446 -2 -555 8
                           -60 13 -111 13 -112 -7 -9 -293 179 -381 251 l-30 24 2 164 c0 89 6 201 12
                           248 12 86 38 233 47 259 5 15 2 17 109 -74z m4478 -17 c35 -179 52 -403 42
                           -526 l-7 -80 -82 -60 c-95 -70 -258 -174 -264 -168 -2 3 2 54 8 115 12 105 8
                           453 -5 541 -5 34 -3 37 71 90 42 30 106 81 142 112 37 32 70 58 73 58 4 0 13
                           -37 22 -82z m-504 -470 c1 -162 -15 -330 -42 -447 l-12 -53 -100 -43 c-117
                           -51 -502 -181 -511 -173 -3 4 2 62 11 130 8 67 21 213 27 324 l11 200 121 43
                           c127 45 373 154 441 196 36 23 40 23 47 7 4 -9 7 -92 7 -184z m-3422 128 c138
                           -69 209 -99 358 -151 l120 -43 8 -128 c7 -119 30 -375 44 -486 l5 -47 -118 35
                           c-122 37 -336 114 -435 158 l-56 24 -23 129 c-17 95 -24 180 -28 330 -4 186
                           -2 233 13 233 2 0 53 -24 112 -54z m843 -285 c134 -28 357 -59 475 -66 55 -4
                           110 -8 123 -11 l22 -4 0 -310 0 -310 -42 0 c-127 0 -582 57 -644 80 -19 8 -23
                           24 -43 172 -17 130 -40 389 -41 467 0 10 8 12 28 8 15 -3 70 -15 122 -26z
                           m1745 -68 c-15 -237 -49 -530 -64 -545 -11 -11 -309 -57 -475 -73 -88 -8 -208
                           -15 -268 -15 l-108 0 0 309 0 309 118 6 c263 15 542 51 702 91 30 7 66 14 78
                           14 l24 1 -7 -97z m-3179 -234 c38 -26 130 -80 204 -121 l133 -73 48 -145 c59
                           -182 102 -285 200 -477 l78 -151 -119 48 c-66 27 -163 71 -217 99 -97 48 -98
                           49 -140 122 -130 229 -211 441 -277 732 -8 33 -14 35 90 -34z m4490 -86 c-64
                           -251 -168 -490 -307 -704 l-50 -76 -97 -41 c-90 -38 -230 -92 -238 -92 -2 0
                           12 28 32 63 110 195 187 370 264 597 l55 164 110 61 c61 33 142 81 180 106 43
                           28 71 41 73 34 2 -7 -8 -57 -22 -112z m-606 -222 c0 -5 -20 -58 -44 -118 -58
                           -145 -160 -348 -244 -486 -121 -198 -106 -183 -199 -206 -72 -18 -278 -61
                           -294 -61 -3 0 21 78 53 174 32 95 78 251 102 346 l43 174 104 27 c111 29 306
                           93 404 133 69 28 75 29 75 17z m-3025 -96 c72 -23 171 -52 220 -65 l90 -23 38
                           -153 c30 -122 126 -437 153 -501 8 -20 -13 -16 -202 29 l-175 42 -66 105 c-73
                           116 -219 393 -253 481 -12 30 -32 83 -45 117 l-24 62 67 -26 c37 -14 126 -44
                           197 -68z m939 -190 c88 -8 184 -15 213 -15 l53 0 1 -47 c0 -27 6 -169 12 -316
                           7 -148 10 -270 7 -272 -7 -4 -227 12 -359 26 l-113 12 -20 46 c-50 115 -188
                           562 -188 608 0 5 53 1 118 -9 64 -9 189 -24 276 -33z m1239 -72 c-32 -123
                           -135 -439 -167 -514 -15 -35 -17 -36 -83 -43 -134 -13 -329 -25 -405 -26 -73
                           0 -78 1 -82 23 -3 12 -7 103 -10 202 -4 99 -9 232 -12 296 l-7 116 169 6 c173
                           7 370 27 504 51 41 8 87 14 101 15 l26 1 -34 -127z m-2416 -506 c48 -18 117
                           -42 152 -52 57 -17 69 -25 107 -75 23 -30 75 -97 116 -149 40 -51 79 -102 87
                           -113 15 -21 -124 60 -219 128 -123 86 -361 294 -338 294 3 0 46 -15 95 -33z
                           m3178 -73 c-83 -74 -192 -158 -279 -217 l-91 -61 38 50 c21 27 61 81 90 121
                           l52 72 105 35 c58 19 110 34 115 35 6 0 -8 -16 -30 -35z m-2466 -124 l124 -23
                           41 -91 c22 -50 64 -138 92 -196 29 -58 51 -106 49 -108 -2 -2 -17 -1 -34 3
                           -33 7 -200 173 -332 330 -87 103 -99 121 -78 113 8 -3 70 -16 138 -28z m1889
                           -67 c-35 -42 -116 -131 -180 -198 -103 -107 -122 -123 -162 -132 -25 -6 -46
                           -9 -46 -6 0 2 20 43 44 91 24 48 60 125 81 172 21 47 41 89 46 94 6 7 221 51
                           267 55 7 1 -15 -33 -50 -76z m-1268 -13 c79 -6 144 -11 146 -13 2 -3 24 -336
                           24 -372 l0 -27 -97 7 c-54 4 -103 12 -109 19 -16 16 -123 213 -172 320 l-42
                           89 53 -6 c29 -4 118 -11 197 -17z m800 3 c0 -5 -44 -93 -98 -198 l-98 -190
                           -39 -6 c-22 -4 -72 -10 -111 -13 l-71 -7 -7 101 c-3 55 -9 145 -12 200 l-7
                           100 109 1 c60 1 143 5 184 9 111 10 150 11 150 3z"/>
                           </g>
                           </svg>
                           
                        }
                    </button>
                    {isOpen && (
                        <div className="firstHeader__dropdown">
                            <button className="firstHeader__dropdown-item" onClick={() => chanageLanguage('en')}>English</button>
                            <button className="firstHeader__dropdown-item" onClick={() => chanageLanguage('jp')}>日本語</button>
                        </div>
                    )}
                </div>


            </div>
            <div className="secondHeader">
                <NavLink className="secondHeader__item LandingPage" to={'/'}>{t('header.home')}</NavLink>
                <NavLink className="secondHeader__item News" to={'News'}>{t('header.new')}</NavLink>
                <NavLink className="secondHeader__item AboutUs" to={'AboutUs'}>{t('header.about')}</NavLink>
            </div>
        </header>

        <Routes>
            <Route path="/Download" element={<Download/>}/>
            <Route path="/News" element={<News/>}/>
            <Route path="/AboutUs" element={<AboutUs/>}/>
        </Routes>
        </>
    )
}